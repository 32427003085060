import { HashRouter, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Files from "../Pages/Home/Files/Files";
import Layout from "../component/Layout/Layout";

const App = () => {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    {/* Home Route */}
                    <Route index element={<Home />} />
                </Route>

                {/* Files Route */}
                <Route path="/files" element={<Layout />}>
                    <Route index element={<Files />} />
                </Route>
            </Routes>
        </HashRouter>
    );
};
export default App;
