import React from "react";

export default function Blog() {
  return (
    <div className="cs-modal" data-modal="details">
      <div className="cs-close_overlay"></div>
      <div className="cs-modal_in">
        <div className="cs-modal_container cs-white_bg">
          <button className="cs-close_modal cs-center cs-primary_bg">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM10.2929 0.292893L0.292893 10.2929L1.70711 11.7071L11.7071 1.70711L10.2929 0.292893ZM0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711Z"
                fill="white"
              />
            </svg>
          </button>
          <div className="cs-height_60 cs-height_lg_60"></div>
          <div className="cs-blog_details">
            <ul className="cs-post_meta cs-mp0">
              <li>
                <span className="cs-medium">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="0.88em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M313.6 304c-28.7 0-42.5 16-89.6 16c-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4c14.6 0 38.3 16 89.6 16c51.7 0 74.9-16 89.6-16c47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0S80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96s-96-43.1-96-96s43.1-96 96-96z"
                    />
                  </svg>
                </span>
                admin
              </li>
              <li>
                <span className="cs-medium">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="0.88em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M152 64h144V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40h40c35.3 0 64 28.65 64 64v320c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64V128c0-35.35 28.65-64 64-64h40V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40zM48 448c0 8.8 7.16 16 16 16h320c8.8 0 16-7.2 16-16V192H48v256z"
                    />
                  </svg>
                </span>
                12.09.2022
              </li>
            </ul>
            <h2>
              Point of sale software card of using a payroll software all for
              your company!
            </h2>
            <img
              src="../../../img/post_details_1.jpeg"
              alt="Details"
              className="cs-radius_10 w-100"
            />
            <h3>How to used point of sale software card?</h3>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ventore veritatis et quasi architecto beatae vitae dicta sunt
              explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur
              fugit, consequuntur magni dolores eos qui ratione voluptatem sequi
              nesciuntBut in certain circumstances and owing to the claims.
            </p>
            <p>
              Nor again is there anyone who loves or pursues or desires to
              obtain pain of itself, because it is pain, but because
              occasionally circumstances occ in which toil and pain can procure
              him some great pleasure. To take a trivial example, which of us
              ever undertakes laborious physical exercise, except to obtain some
              advantage from it? But who has any right to find fault with a man
              who chooses.
            </p>
            <div className="cs-height_10 cs-height_lg_10"></div>
            <div className="row">
              <div className="col-lg-6">
                <img
                  src="../../../img/post_details_2.jpeg"
                  alt="Details"
                  className="cs-radius_10 w-100"
                />
              </div>
              <div className="col-lg-6">
                <img
                  src="../../../img/post_details_3.jpeg"
                  alt="Details"
                  className="cs-radius_10 w-100"
                />
              </div>
            </div>
            <p>
              These cases are perfectly simple and easy to distinguish. In a
              free hour, when our power of choice is untrammelled and when
              nothing prevents our being able to do what we like best, every
              pleasure is to be welcomed and every pain avoided. But in certain
              circumstances and owing to the claims of duty or the obligations
              of business it will frequently occur that pleasures have.
            </p>
            <div className="cs-height_10 cs-height_lg_10"></div>
            <blockquote>
              <svg
                width="33"
                height="23"
                viewBox="0 0 33 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2387 0H0.955626C0.427792 0 0 0.411081 0 0.918297V12.7215C0 13.2287 0.427792 13.6398 0.955626 13.6398H6.14139V21.574C6.14139 22.081 6.56918 22.4923 7.09702 22.4923H10.168C10.5791 22.4923 10.9444 22.2393 11.0743 21.8643L14.145 13.0121C14.1776 12.9183 14.1943 12.8203 14.1943 12.7215V0.918297C14.1943 0.411081 13.7665 0 13.2387 0V0ZM12.283 12.5725L9.47911 20.6555H8.05264V12.7215C8.05264 12.2143 7.62485 11.8032 7.09702 11.8032H1.91125V1.83659H12.283V12.5725Z"
                  fill="black"
                />
                <path
                  d="M31.6635 0H19.3804C18.8526 0 18.4248 0.411081 18.4248 0.918297V12.7215C18.4248 13.2287 18.8526 13.6398 19.3804 13.6398H24.5664V21.574C24.5664 22.081 24.9942 22.4923 25.5221 22.4923H28.5928C29.0041 22.4923 29.3692 22.2393 29.4994 21.8643L32.5701 13.0121C32.6024 12.9183 32.6191 12.8203 32.6191 12.7215V0.918297C32.6191 0.411081 32.1913 0 31.6635 0V0ZM30.7078 12.5725L27.9039 20.6555H26.4777V12.7215C26.4777 12.2143 26.0497 11.8032 25.5221 11.8032H20.3361V1.83659H30.7078V12.5725Z"
                  fill="black"
                />
              </svg>
              <p>
                The best point of sale software used good and very fast .Time
                saving or effective software Pos ut omnis iste natus error sit
                voluptatem accusantium doloremque laudantium, totam rem aperiam,
                illo inventore veritatis et quasi architecto beatae vitae dicta
                sunt explicabo.{" "}
              </p>
              <small>Mixon Max</small>
            </blockquote>
            <h3>Cost Effective and Good software </h3>
            <p>
              Many desktop publishing packages and web page editors now use
              Lorem Ipsum as their default model text, and a search for 'lorem
              ipsum' will uncover many web sites still in their infancy. Various
              versions have evolved over the years, sometimes by accident,
              sometimes on purpose to the claims of duty or the obligations of
              business it will frequently occur that pleasures have.
            </p>
            <div className="row">
              <div className="col-lg-6">
                <h3>Our Approach</h3>
                <p>
                  Pos cost effective unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem quae ab illo
                  inventore veritatis et quasi
                </p>
                <ul>
                  <li>Eagy to used and time saving</li>
                  <li>Effective cast pament</li>
                  <li>High speed process</li>
                  <li>Customer data saved</li>
                </ul>
              </div>
              <div className="col-lg-6">
                <img
                  src="../../../img/post_details_4.jpeg"
                  alt="Details"
                  className="cs-radius_10 w-100"
                />
              </div>
            </div>
          </div>
          <div id="comments" className="comments-area">
            <div className="cs-comment_wrapper">
              <h2 className="comments-title">Comments (3)</h2>
              <ol className="comment-list">
                <li>
                  <div className="comment-body">
                    <div className="comment-author">
                      <img
                        alt="Avatar"
                        src="../../../img/avatar_4.png"
                        className="avatar"
                      />
                      <cite className="fn">
                        <a href="#">Menila</a>
                      </cite>
                    </div>
                    <div className="comment-meta">
                      <a href="#">20 Apr, 2020</a>{" "}
                    </div>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt but in certain
                      circumstances.
                    </p>
                    <div className="reply">
                      <a href="#">Reply</a>
                    </div>
                  </div>
                  <ol className="children">
                    <li>
                      <div className="comment-body">
                        <div className="comment-author">
                          <img
                            alt="Avatar"
                            src="../../../img/avatar_5.png"
                            className="avatar"
                          />
                          <cite className="fn">
                            <a href="#">Karla Swit</a>
                          </cite>
                        </div>
                        <div className="comment-meta">
                          <a href="#">9 May, 2020</a>{" "}
                        </div>
                        <p>
                          YSed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam, eaque ipsa quae ab illo inventore
                          veritatis et quasi architecto beatae vitae dicta sunt
                          but in certain circumstances.
                        </p>
                        <div className="reply">
                          <a href="#">Reply</a>
                        </div>
                      </div>
                    </li>
                  </ol>
                </li>
                <li>
                  <div className="comment-body">
                    <div className="comment-author">
                      <img
                        alt="Avatar"
                        src="../../../img/avatar_6.png"
                        className="avatar"
                      />
                      <cite className="fn">
                        <a href="#">George Steven</a>
                      </cite>
                    </div>
                    <div className="comment-meta">
                      <a href="#">November 24, 2020</a>{" "}
                    </div>
                    <p>Great work</p>
                    <div className="reply">
                      <a href="#">Reply</a>
                    </div>
                  </div>
                </li>
              </ol>
            </div>
            <div className="comment-respond">
              <h3 className="comment-reply-title">Write Your Comment</h3>
              <form id="commentform">
                <div className="row">
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="cs-form_field cs-border_color"
                      placeholder="Name"
                    />
                    <div className="cs-height_20 cs-height_lg_20"></div>
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="cs-form_field cs-border_color"
                      placeholder="Email"
                    />
                    <div className="cs-height_20 cs-height_lg_20"></div>
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      cols="30"
                      rows="10"
                      className="cs-form_field cs-border_color"
                      placeholder="Write your comment"
                    ></textarea>
                    <div className="cs-height_20 cs-height_lg_20"></div>
                  </div>
                  <div className="col-lg-12">
                    <button className="cs-btn cs-size_md">
                      <span>Post Comment</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="cs-height_70 cs-height_lg_60"></div>
        </div>
      </div>
    </div>
  );
}
