import React from "react";

export default function Post() {
  return (
    <section className="cs-bg" data-src="../../../img/feature_bg.svg" id="news">
      <div className="cs-height_95 cs-height_lg_70"></div>
      <div className="container">
        <div className="cs-seciton_heading cs-style1 text-center">
          <div
            className="cs-section_subtitle wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            Letest News
          </div>
          <div className="cs-height_10 cs-height_lg_10"></div>
          <h3 className="cs-section_title">Point of sale latest news</h3>
        </div>
        <div className="cs-height_50 cs-height_lg_40"></div>
        <div className="cs-slider cs-style1 cs-gap-24">
          <div
            className="cs-slider_container"
            data-autoplay="0"
            data-loop="1"
            data-speed="600"
            data-fade-slide="0"
            data-slides-per-view="responsive"
            data-xs-slides="1"
            data-sm-slides="2"
            data-md-slides="3"
            data-lg-slides="4"
            data-add-slides="4"
          >
            <div className="cs-slider_wrapper">
              <div className="cs-slide">
                <div className="cs-post cs-style1">
                  <div
                    className="cs-post_thumb cs-modal_btn"
                    data-modal="details"
                  >
                    <div
                      className="cs-post_thumb_in cs-bg"
                      data-src="../../../img/post_1.jpeg"
                    ></div>
                  </div>
                  <div className="cs-post_info">
                    <ul className="cs-post_meta cs-mp0">
                      <li>
                        <span className="cs-medium">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="0.88em"
                            height="1em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M313.6 304c-28.7 0-42.5 16-89.6 16c-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4c14.6 0 38.3 16 89.6 16c51.7 0 74.9-16 89.6-16c47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0S80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96s-96-43.1-96-96s43.1-96 96-96z"
                            />
                          </svg>
                        </span>
                        admin
                      </li>
                      <li>
                        <span className="cs-medium">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="0.88em"
                            height="1em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M152 64h144V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40h40c35.3 0 64 28.65 64 64v320c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64V128c0-35.35 28.65-64 64-64h40V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40zM48 448c0 8.8 7.16 16 16 16h320c8.8 0 16-7.2 16-16V192H48v256z"
                            />
                          </svg>
                        </span>
                        12.09.2022
                      </li>
                    </ul>
                    <h2
                      className="cs-post_title cs-modal_btn"
                      data-modal="details"
                    >
                      Point of sale software card usde
                    </h2>
                    <span
                      className="cs-text_btn cs-modal_btn"
                      data-modal="details"
                    >
                      <span>Read More</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M13.3 17.275q-.3-.3-.288-.725q.013-.425.313-.725L16.15 13H5q-.425 0-.713-.288Q4 12.425 4 12t.287-.713Q4.575 11 5 11h11.15L13.3 8.15q-.3-.3-.3-.713q0-.412.3-.712t.713-.3q.412 0 .712.3L19.3 11.3q.15.15.213.325q.062.175.062.375t-.062.375q-.063.175-.213.325l-4.6 4.6q-.275.275-.687.275q-.413 0-.713-.3Z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="cs-slide">
                <div className="cs-post cs-style1">
                  <div
                    className="cs-post_thumb cs-modal_btn"
                    data-modal="details"
                  >
                    <div
                      className="cs-post_thumb_in cs-bg"
                      data-src="../../../img/post_2.jpeg"
                    ></div>
                  </div>
                  <div className="cs-post_info">
                    <ul className="cs-post_meta cs-mp0">
                      <li>
                        <span className="cs-medium">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="0.88em"
                            height="1em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M313.6 304c-28.7 0-42.5 16-89.6 16c-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4c14.6 0 38.3 16 89.6 16c51.7 0 74.9-16 89.6-16c47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0S80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96s-96-43.1-96-96s43.1-96 96-96z"
                            />
                          </svg>
                        </span>
                        admin
                      </li>
                      <li>
                        <span className="cs-medium">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="0.88em"
                            height="1em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M152 64h144V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40h40c35.3 0 64 28.65 64 64v320c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64V128c0-35.35 28.65-64 64-64h40V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40zM48 448c0 8.8 7.16 16 16 16h320c8.8 0 16-7.2 16-16V192H48v256z"
                            />
                          </svg>
                        </span>
                        12.09.2022
                      </li>
                    </ul>
                    <h2
                      className="cs-post_title cs-modal_btn"
                      data-modal="details"
                    >
                      Best super shop point of sale setup
                    </h2>
                    <span
                      className="cs-text_btn cs-modal_btn"
                      data-modal="details"
                    >
                      <span>Read More</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M13.3 17.275q-.3-.3-.288-.725q.013-.425.313-.725L16.15 13H5q-.425 0-.713-.288Q4 12.425 4 12t.287-.713Q4.575 11 5 11h11.15L13.3 8.15q-.3-.3-.3-.713q0-.412.3-.712t.713-.3q.412 0 .712.3L19.3 11.3q.15.15.213.325q.062.175.062.375t-.062.375q-.063.175-.213.325l-4.6 4.6q-.275.275-.687.275q-.413 0-.713-.3Z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="cs-slide">
                <div className="cs-post cs-style1">
                  <div
                    className="cs-post_thumb cs-modal_btn"
                    data-modal="details"
                  >
                    <div
                      className="cs-post_thumb_in cs-bg"
                      data-src="../../../img/post_3.jpeg"
                    ></div>
                  </div>
                  <div className="cs-post_info">
                    <ul className="cs-post_meta cs-mp0">
                      <li>
                        <span className="cs-medium">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="0.88em"
                            height="1em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M313.6 304c-28.7 0-42.5 16-89.6 16c-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4c14.6 0 38.3 16 89.6 16c51.7 0 74.9-16 89.6-16c47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0S80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96s-96-43.1-96-96s43.1-96 96-96z"
                            />
                          </svg>
                        </span>
                        admin
                      </li>
                      <li>
                        <span className="cs-medium">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="0.88em"
                            height="1em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M152 64h144V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40h40c35.3 0 64 28.65 64 64v320c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64V128c0-35.35 28.65-64 64-64h40V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40zM48 448c0 8.8 7.16 16 16 16h320c8.8 0 16-7.2 16-16V192H48v256z"
                            />
                          </svg>
                        </span>
                        12.09.2022
                      </li>
                    </ul>
                    <h2
                      className="cs-post_title cs-modal_btn"
                      data-modal="details"
                    >
                      Computer new point of saie working good
                    </h2>
                    <span
                      className="cs-text_btn cs-modal_btn"
                      data-modal="details"
                    >
                      <span>Read More</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M13.3 17.275q-.3-.3-.288-.725q.013-.425.313-.725L16.15 13H5q-.425 0-.713-.288Q4 12.425 4 12t.287-.713Q4.575 11 5 11h11.15L13.3 8.15q-.3-.3-.3-.713q0-.412.3-.712t.713-.3q.412 0 .712.3L19.3 11.3q.15.15.213.325q.062.175.062.375t-.062.375q-.063.175-.213.325l-4.6 4.6q-.275.275-.687.275q-.413 0-.713-.3Z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="cs-slide">
                <div className="cs-post cs-style1">
                  <div
                    className="cs-post_thumb cs-modal_btn"
                    data-modal="details"
                  >
                    <div
                      className="cs-post_thumb_in cs-bg"
                      data-src="../../../img/post_4.jpeg"
                    ></div>
                  </div>
                  <div className="cs-post_info">
                    <ul className="cs-post_meta cs-mp0">
                      <li>
                        <span className="cs-medium">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="0.88em"
                            height="1em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M313.6 304c-28.7 0-42.5 16-89.6 16c-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4c14.6 0 38.3 16 89.6 16c51.7 0 74.9-16 89.6-16c47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0S80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96s-96-43.1-96-96s43.1-96 96-96z"
                            />
                          </svg>
                        </span>
                        admin
                      </li>
                      <li>
                        <span className="cs-medium">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="0.88em"
                            height="1em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M152 64h144V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40h40c35.3 0 64 28.65 64 64v320c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64V128c0-35.35 28.65-64 64-64h40V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40zM48 448c0 8.8 7.16 16 16 16h320c8.8 0 16-7.2 16-16V192H48v256z"
                            />
                          </svg>
                        </span>
                        12.09.2022
                      </li>
                    </ul>
                    <h2
                      className="cs-post_title cs-modal_btn"
                      data-modal="details"
                    >
                      Connection pos to phone payment option
                    </h2>
                    <span
                      className="cs-text_btn cs-modal_btn"
                      data-modal="details"
                    >
                      <span>Read More</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M13.3 17.275q-.3-.3-.288-.725q.013-.425.313-.725L16.15 13H5q-.425 0-.713-.288Q4 12.425 4 12t.287-.713Q4.575 11 5 11h11.15L13.3 8.15q-.3-.3-.3-.713q0-.412.3-.712t.713-.3q.412 0 .712.3L19.3 11.3q.15.15.213.325q.062.175.062.375t-.062.375q-.063.175-.213.325l-4.6 4.6q-.275.275-.687.275q-.413 0-.713-.3Z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="cs-slide">
                <div className="cs-post cs-style1">
                  <div
                    className="cs-post_thumb cs-modal_btn"
                    data-modal="details"
                  >
                    <div
                      className="cs-post_thumb_in cs-bg"
                      data-src="../../../img/post_2.jpeg"
                    ></div>
                  </div>
                  <div className="cs-post_info">
                    <ul className="cs-post_meta cs-mp0">
                      <li>
                        <span className="cs-medium">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="0.88em"
                            height="1em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M313.6 304c-28.7 0-42.5 16-89.6 16c-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4c14.6 0 38.3 16 89.6 16c51.7 0 74.9-16 89.6-16c47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0S80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96s-96-43.1-96-96s43.1-96 96-96z"
                            />
                          </svg>
                        </span>
                        admin
                      </li>
                      <li>
                        <span className="cs-medium">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="0.88em"
                            height="1em"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M152 64h144V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40h40c35.3 0 64 28.65 64 64v320c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64V128c0-35.35 28.65-64 64-64h40V24c0-13.25 10.7-24 24-24s24 10.75 24 24v40zM48 448c0 8.8 7.16 16 16 16h320c8.8 0 16-7.2 16-16V192H48v256z"
                            />
                          </svg>
                        </span>
                        12.09.2022
                      </li>
                    </ul>
                    <h2
                      className="cs-post_title cs-modal_btn"
                      data-modal="details"
                    >
                      Best super shop point of sale setup
                    </h2>
                    <span
                      className="cs-text_btn cs-modal_btn"
                      data-modal="details"
                    >
                      <span>Read More</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M13.3 17.275q-.3-.3-.288-.725q.013-.425.313-.725L16.15 13H5q-.425 0-.713-.288Q4 12.425 4 12t.287-.713Q4.575 11 5 11h11.15L13.3 8.15q-.3-.3-.3-.713q0-.412.3-.712t.713-.3q.412 0 .712.3L19.3 11.3q.15.15.213.325q.062.175.062.375t-.062.375q-.063.175-.213.325l-4.6 4.6q-.275.275-.687.275q-.413 0-.713-.3Z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cs-pagination cs-style1"></div>
        </div>
      </div>
      <div className="cs-height_100 cs-height_lg_70"></div>
    </section>
  );
}
