// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA2LQwQLK4T1AYjtuojLdDK-uJcY2PZEMw",
    authDomain: "spotwells-867a4.firebaseapp.com",
    projectId: "spotwells-867a4",
    storageBucket: "spotwells-867a4.appspot.com",
    messagingSenderId: "910394687924",
    appId: "1:910394687924:web:b484f5fe4009d5efbd72e1",
    measurementId: "G-2QMNW7KS7B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
