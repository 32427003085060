import React from "react";

export default function Item({ name, img, pdf }) {
    return (
        <div className="cs-slider_wrapper">
            <div className="cs-slide" style={{ maxWidth: 450 }}>
                <div className="cs-post cs-style1">
                    <div
                        className="cs-post_thumb cs-modal_btn"
                        data-modal="details"
                    >
                        <div
                            className="cs-post_thumb_in cs-bg"
                            style={{ backgroundImage: `url(${img})`, width: 450, heigh: 289 }}
                        ></div>
                    </div>
                    <div className="cs-post_info">
                        <h2
                            className="cs-post_title cs-modal_btn"
                            data-modal="details"
                        >
                            {name}
                        </h2>
                        <span
                            className="cs-text_btn cs-modal_btn"
                            data-modal="details"
                        >
                      <span>
                          <a href={pdf}>Download PDF</a>
                      </span>
                    </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
