import React from "react";

export default function Pricing() {
  return (
    <section
      className="cs-bg"
      data-src="../../../img/feature_bg.svg"
      id="pricing"
    >
      <div className="cs-height_95 cs-height_lg_70"></div>
      <div className="container">
        <div className="cs-seciton_heading cs-style1 text-center">
          <div
            className="cs-section_subtitle wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            Pricing Plan
          </div>
          <div className="cs-height_10 cs-height_lg_10"></div>
          <h3 className="cs-section_title">The best choice for you</h3>
        </div>
        <div className="cs-height_50 cs-height_lg_40"></div>
        <div className="row">
          <div className="col-lg-4">
            <div className="cs-pricing_table cs-style1">
              <div className="cs-pricing_head">
                <div className="cs-pricing_heading">
                  <div className="cs-pricing_icon cs-center">
                    <img
                      src="../../../img/icons/pricing_icon_1.svg"
                      alt="Icon"
                    />
                  </div>
                  <h2 className="cs-pricing_title cs-m0">Silver</h2>
                </div>
                <div className="cs-price cs-primary_font">
                  <b className="cs-accent_color">$43</b>
                  <span>/month</span>
                </div>
                <div className="cs-pricing_lable">Get 7 Days Free Trial</div>
              </div>
              <div className="cs-pricing_body">
                <ul className="cs-mp0 cs-pricing_feature">
                  <li>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.18215 0C3.771 0 0.18219 3.58872 0.18219 7.99996C0.18219 12.4112 3.771 16 8.18215 16C12.5933 16 16.1821 12.4112 16.1821 7.99996C16.1821 3.58872 12.5934 0 8.18215 0ZM12.7759 6.6487L7.7499 11.6747C7.53619 11.8884 7.25212 12.006 6.94993 12.006C6.64774 12.006 6.36366 11.8884 6.14996 11.6747L3.58843 9.11314C3.37473 8.89944 3.25702 8.61536 3.25702 8.31317C3.25702 8.01089 3.37473 7.72682 3.58843 7.51311C3.80205 7.29941 4.08613 7.18171 4.3884 7.18171C4.69059 7.18171 4.97475 7.29941 5.18837 7.5132L6.94984 9.27459L11.1758 5.04867C11.3895 4.83497 11.6735 4.71735 11.9757 4.71735C12.2779 4.71735 12.562 4.83497 12.7757 5.04867C13.217 5.48994 13.217 6.2076 12.7759 6.6487Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Unlimited users</span>
                  </li>
                  <li>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.18215 0C3.771 0 0.18219 3.58872 0.18219 7.99996C0.18219 12.4112 3.771 16 8.18215 16C12.5933 16 16.1821 12.4112 16.1821 7.99996C16.1821 3.58872 12.5934 0 8.18215 0ZM12.7759 6.6487L7.7499 11.6747C7.53619 11.8884 7.25212 12.006 6.94993 12.006C6.64774 12.006 6.36366 11.8884 6.14996 11.6747L3.58843 9.11314C3.37473 8.89944 3.25702 8.61536 3.25702 8.31317C3.25702 8.01089 3.37473 7.72682 3.58843 7.51311C3.80205 7.29941 4.08613 7.18171 4.3884 7.18171C4.69059 7.18171 4.97475 7.29941 5.18837 7.5132L6.94984 9.27459L11.1758 5.04867C11.3895 4.83497 11.6735 4.71735 11.9757 4.71735C12.2779 4.71735 12.562 4.83497 12.7757 5.04867C13.217 5.48994 13.217 6.2076 12.7759 6.6487Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Unlimited customers</span>
                  </li>
                  <li className="cs-inactive">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.99583 0C3.57967 0 0 3.58154 0 8C0 12.4185 3.57967 16 7.99583 16C12.412 16 15.9917 12.4185 15.9917 8C15.9917 3.58154 12.412 0 7.99583 0ZM6.58529 5.71897C6.52898 5.65851 6.46108 5.61002 6.38563 5.57639C6.31018 5.54275 6.22873 5.52466 6.14615 5.52321C6.06356 5.52175 5.98153 5.53695 5.90495 5.5679C5.82836 5.59885 5.75879 5.64492 5.70038 5.70335C5.64198 5.76179 5.59593 5.8314 5.565 5.90802C5.53407 5.98465 5.51887 6.06672 5.52033 6.14935C5.52179 6.23198 5.53986 6.31347 5.57348 6.38895C5.6071 6.46444 5.65557 6.53238 5.716 6.58872L7.12654 8L5.716 9.41128C5.65557 9.46762 5.6071 9.53556 5.57348 9.61105C5.53986 9.68653 5.52179 9.76802 5.52033 9.85065C5.51887 9.93328 5.53407 10.0154 5.565 10.092C5.59593 10.1686 5.64198 10.2382 5.70038 10.2966C5.75879 10.3551 5.82836 10.4012 5.90495 10.4321C5.98153 10.4631 6.06356 10.4783 6.14615 10.4768C6.22873 10.4753 6.31018 10.4572 6.38563 10.4236C6.46108 10.39 6.52898 10.3415 6.58529 10.281L7.99583 8.86974L9.40638 10.281C9.46269 10.3415 9.53059 10.39 9.60604 10.4236C9.68149 10.4572 9.76293 10.4753 9.84552 10.4768C9.92811 10.4783 10.0101 10.4631 10.0867 10.4321C10.1633 10.4012 10.2329 10.3551 10.2913 10.2966C10.3497 10.2382 10.3957 10.1686 10.4267 10.092C10.4576 10.0154 10.4728 9.93328 10.4713 9.85065C10.4699 9.76802 10.4518 9.68653 10.4182 9.61105C10.3846 9.53556 10.3361 9.46762 10.2757 9.41128L8.86513 8L10.2757 6.58872C10.3361 6.53238 10.3846 6.46444 10.4182 6.38895C10.4518 6.31347 10.4699 6.23198 10.4713 6.14935C10.4728 6.06672 10.4576 5.98465 10.4267 5.90802C10.3957 5.8314 10.3497 5.76179 10.2913 5.70335C10.2329 5.64492 10.1633 5.59885 10.0867 5.5679C10.0101 5.53695 9.92811 5.52175 9.84552 5.52321C9.76293 5.52466 9.68149 5.54275 9.60604 5.57639C9.53059 5.61002 9.46269 5.65851 9.40638 5.71897L7.99583 7.13026L6.58529 5.71897Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Access from anywhere</span>
                  </li>
                  <li className="cs-inactive">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.99583 0C3.57967 0 0 3.58154 0 8C0 12.4185 3.57967 16 7.99583 16C12.412 16 15.9917 12.4185 15.9917 8C15.9917 3.58154 12.412 0 7.99583 0ZM6.58529 5.71897C6.52898 5.65851 6.46108 5.61002 6.38563 5.57639C6.31018 5.54275 6.22873 5.52466 6.14615 5.52321C6.06356 5.52175 5.98153 5.53695 5.90495 5.5679C5.82836 5.59885 5.75879 5.64492 5.70038 5.70335C5.64198 5.76179 5.59593 5.8314 5.565 5.90802C5.53407 5.98465 5.51887 6.06672 5.52033 6.14935C5.52179 6.23198 5.53986 6.31347 5.57348 6.38895C5.6071 6.46444 5.65557 6.53238 5.716 6.58872L7.12654 8L5.716 9.41128C5.65557 9.46762 5.6071 9.53556 5.57348 9.61105C5.53986 9.68653 5.52179 9.76802 5.52033 9.85065C5.51887 9.93328 5.53407 10.0154 5.565 10.092C5.59593 10.1686 5.64198 10.2382 5.70038 10.2966C5.75879 10.3551 5.82836 10.4012 5.90495 10.4321C5.98153 10.4631 6.06356 10.4783 6.14615 10.4768C6.22873 10.4753 6.31018 10.4572 6.38563 10.4236C6.46108 10.39 6.52898 10.3415 6.58529 10.281L7.99583 8.86974L9.40638 10.281C9.46269 10.3415 9.53059 10.39 9.60604 10.4236C9.68149 10.4572 9.76293 10.4753 9.84552 10.4768C9.92811 10.4783 10.0101 10.4631 10.0867 10.4321C10.1633 10.4012 10.2329 10.3551 10.2913 10.2966C10.3497 10.2382 10.3957 10.1686 10.4267 10.092C10.4576 10.0154 10.4728 9.93328 10.4713 9.85065C10.4699 9.76802 10.4518 9.68653 10.4182 9.61105C10.3846 9.53556 10.3361 9.46762 10.2757 9.41128L8.86513 8L10.2757 6.58872C10.3361 6.53238 10.3846 6.46444 10.4182 6.38895C10.4518 6.31347 10.4699 6.23198 10.4713 6.14935C10.4728 6.06672 10.4576 5.98465 10.4267 5.90802C10.3957 5.8314 10.3497 5.76179 10.2913 5.70335C10.2329 5.64492 10.1633 5.59885 10.0867 5.5679C10.0101 5.53695 9.92811 5.52175 9.84552 5.52321C9.76293 5.52466 9.68149 5.54275 9.60604 5.57639C9.53059 5.61002 9.46269 5.65851 9.40638 5.71897L7.99583 7.13026L6.58529 5.71897Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Accept payments 24/7</span>
                  </li>
                  <li className="cs-inactive">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.99583 0C3.57967 0 0 3.58154 0 8C0 12.4185 3.57967 16 7.99583 16C12.412 16 15.9917 12.4185 15.9917 8C15.9917 3.58154 12.412 0 7.99583 0ZM6.58529 5.71897C6.52898 5.65851 6.46108 5.61002 6.38563 5.57639C6.31018 5.54275 6.22873 5.52466 6.14615 5.52321C6.06356 5.52175 5.98153 5.53695 5.90495 5.5679C5.82836 5.59885 5.75879 5.64492 5.70038 5.70335C5.64198 5.76179 5.59593 5.8314 5.565 5.90802C5.53407 5.98465 5.51887 6.06672 5.52033 6.14935C5.52179 6.23198 5.53986 6.31347 5.57348 6.38895C5.6071 6.46444 5.65557 6.53238 5.716 6.58872L7.12654 8L5.716 9.41128C5.65557 9.46762 5.6071 9.53556 5.57348 9.61105C5.53986 9.68653 5.52179 9.76802 5.52033 9.85065C5.51887 9.93328 5.53407 10.0154 5.565 10.092C5.59593 10.1686 5.64198 10.2382 5.70038 10.2966C5.75879 10.3551 5.82836 10.4012 5.90495 10.4321C5.98153 10.4631 6.06356 10.4783 6.14615 10.4768C6.22873 10.4753 6.31018 10.4572 6.38563 10.4236C6.46108 10.39 6.52898 10.3415 6.58529 10.281L7.99583 8.86974L9.40638 10.281C9.46269 10.3415 9.53059 10.39 9.60604 10.4236C9.68149 10.4572 9.76293 10.4753 9.84552 10.4768C9.92811 10.4783 10.0101 10.4631 10.0867 10.4321C10.1633 10.4012 10.2329 10.3551 10.2913 10.2966C10.3497 10.2382 10.3957 10.1686 10.4267 10.092C10.4576 10.0154 10.4728 9.93328 10.4713 9.85065C10.4699 9.76802 10.4518 9.68653 10.4182 9.61105C10.3846 9.53556 10.3361 9.46762 10.2757 9.41128L8.86513 8L10.2757 6.58872C10.3361 6.53238 10.3846 6.46444 10.4182 6.38895C10.4518 6.31347 10.4699 6.23198 10.4713 6.14935C10.4728 6.06672 10.4576 5.98465 10.4267 5.90802C10.3957 5.8314 10.3497 5.76179 10.2913 5.70335C10.2329 5.64492 10.1633 5.59885 10.0867 5.5679C10.0101 5.53695 9.92811 5.52175 9.84552 5.52321C9.76293 5.52466 9.68149 5.54275 9.60604 5.57639C9.53059 5.61002 9.46269 5.65851 9.40638 5.71897L7.99583 7.13026L6.58529 5.71897Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Free Support</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="cs-height_25 cs-height_lg_25"></div>
          </div>
          <div className="col-lg-4">
            <div className="cs-pricing_table cs-style1">
              <div className="cs-pricing_head">
                <div className="cs-pricing_heading">
                  <div className="cs-pricing_icon cs-center">
                    <img
                      src="../../../img/icons/pricing_icon_2.svg"
                      alt="Icon"
                    />
                  </div>
                  <h2 className="cs-pricing_title cs-m0">Gold</h2>
                </div>
                <div className="cs-price cs-primary_font">
                  <b className="cs-accent_color">$65</b>
                  <span>/month</span>
                </div>
                <div className="cs-pricing_lable">Get 7 Days Free Trial</div>
              </div>
              <div className="cs-pricing_body">
                <ul className="cs-mp0 cs-pricing_feature">
                  <li>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.18215 0C3.771 0 0.18219 3.58872 0.18219 7.99996C0.18219 12.4112 3.771 16 8.18215 16C12.5933 16 16.1821 12.4112 16.1821 7.99996C16.1821 3.58872 12.5934 0 8.18215 0ZM12.7759 6.6487L7.7499 11.6747C7.53619 11.8884 7.25212 12.006 6.94993 12.006C6.64774 12.006 6.36366 11.8884 6.14996 11.6747L3.58843 9.11314C3.37473 8.89944 3.25702 8.61536 3.25702 8.31317C3.25702 8.01089 3.37473 7.72682 3.58843 7.51311C3.80205 7.29941 4.08613 7.18171 4.3884 7.18171C4.69059 7.18171 4.97475 7.29941 5.18837 7.5132L6.94984 9.27459L11.1758 5.04867C11.3895 4.83497 11.6735 4.71735 11.9757 4.71735C12.2779 4.71735 12.562 4.83497 12.7757 5.04867C13.217 5.48994 13.217 6.2076 12.7759 6.6487Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Unlimited users</span>
                  </li>
                  <li>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.18215 0C3.771 0 0.18219 3.58872 0.18219 7.99996C0.18219 12.4112 3.771 16 8.18215 16C12.5933 16 16.1821 12.4112 16.1821 7.99996C16.1821 3.58872 12.5934 0 8.18215 0ZM12.7759 6.6487L7.7499 11.6747C7.53619 11.8884 7.25212 12.006 6.94993 12.006C6.64774 12.006 6.36366 11.8884 6.14996 11.6747L3.58843 9.11314C3.37473 8.89944 3.25702 8.61536 3.25702 8.31317C3.25702 8.01089 3.37473 7.72682 3.58843 7.51311C3.80205 7.29941 4.08613 7.18171 4.3884 7.18171C4.69059 7.18171 4.97475 7.29941 5.18837 7.5132L6.94984 9.27459L11.1758 5.04867C11.3895 4.83497 11.6735 4.71735 11.9757 4.71735C12.2779 4.71735 12.562 4.83497 12.7757 5.04867C13.217 5.48994 13.217 6.2076 12.7759 6.6487Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Unlimited customers</span>
                  </li>
                  <li>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.18215 0C3.771 0 0.18219 3.58872 0.18219 7.99996C0.18219 12.4112 3.771 16 8.18215 16C12.5933 16 16.1821 12.4112 16.1821 7.99996C16.1821 3.58872 12.5934 0 8.18215 0ZM12.7759 6.6487L7.7499 11.6747C7.53619 11.8884 7.25212 12.006 6.94993 12.006C6.64774 12.006 6.36366 11.8884 6.14996 11.6747L3.58843 9.11314C3.37473 8.89944 3.25702 8.61536 3.25702 8.31317C3.25702 8.01089 3.37473 7.72682 3.58843 7.51311C3.80205 7.29941 4.08613 7.18171 4.3884 7.18171C4.69059 7.18171 4.97475 7.29941 5.18837 7.5132L6.94984 9.27459L11.1758 5.04867C11.3895 4.83497 11.6735 4.71735 11.9757 4.71735C12.2779 4.71735 12.562 4.83497 12.7757 5.04867C13.217 5.48994 13.217 6.2076 12.7759 6.6487Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Access from anywhere</span>
                  </li>
                  <li className="cs-inactive">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.99583 0C3.57967 0 0 3.58154 0 8C0 12.4185 3.57967 16 7.99583 16C12.412 16 15.9917 12.4185 15.9917 8C15.9917 3.58154 12.412 0 7.99583 0ZM6.58529 5.71897C6.52898 5.65851 6.46108 5.61002 6.38563 5.57639C6.31018 5.54275 6.22873 5.52466 6.14615 5.52321C6.06356 5.52175 5.98153 5.53695 5.90495 5.5679C5.82836 5.59885 5.75879 5.64492 5.70038 5.70335C5.64198 5.76179 5.59593 5.8314 5.565 5.90802C5.53407 5.98465 5.51887 6.06672 5.52033 6.14935C5.52179 6.23198 5.53986 6.31347 5.57348 6.38895C5.6071 6.46444 5.65557 6.53238 5.716 6.58872L7.12654 8L5.716 9.41128C5.65557 9.46762 5.6071 9.53556 5.57348 9.61105C5.53986 9.68653 5.52179 9.76802 5.52033 9.85065C5.51887 9.93328 5.53407 10.0154 5.565 10.092C5.59593 10.1686 5.64198 10.2382 5.70038 10.2966C5.75879 10.3551 5.82836 10.4012 5.90495 10.4321C5.98153 10.4631 6.06356 10.4783 6.14615 10.4768C6.22873 10.4753 6.31018 10.4572 6.38563 10.4236C6.46108 10.39 6.52898 10.3415 6.58529 10.281L7.99583 8.86974L9.40638 10.281C9.46269 10.3415 9.53059 10.39 9.60604 10.4236C9.68149 10.4572 9.76293 10.4753 9.84552 10.4768C9.92811 10.4783 10.0101 10.4631 10.0867 10.4321C10.1633 10.4012 10.2329 10.3551 10.2913 10.2966C10.3497 10.2382 10.3957 10.1686 10.4267 10.092C10.4576 10.0154 10.4728 9.93328 10.4713 9.85065C10.4699 9.76802 10.4518 9.68653 10.4182 9.61105C10.3846 9.53556 10.3361 9.46762 10.2757 9.41128L8.86513 8L10.2757 6.58872C10.3361 6.53238 10.3846 6.46444 10.4182 6.38895C10.4518 6.31347 10.4699 6.23198 10.4713 6.14935C10.4728 6.06672 10.4576 5.98465 10.4267 5.90802C10.3957 5.8314 10.3497 5.76179 10.2913 5.70335C10.2329 5.64492 10.1633 5.59885 10.0867 5.5679C10.0101 5.53695 9.92811 5.52175 9.84552 5.52321C9.76293 5.52466 9.68149 5.54275 9.60604 5.57639C9.53059 5.61002 9.46269 5.65851 9.40638 5.71897L7.99583 7.13026L6.58529 5.71897Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Accept payments 24/7</span>
                  </li>
                  <li className="cs-inactive">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.99583 0C3.57967 0 0 3.58154 0 8C0 12.4185 3.57967 16 7.99583 16C12.412 16 15.9917 12.4185 15.9917 8C15.9917 3.58154 12.412 0 7.99583 0ZM6.58529 5.71897C6.52898 5.65851 6.46108 5.61002 6.38563 5.57639C6.31018 5.54275 6.22873 5.52466 6.14615 5.52321C6.06356 5.52175 5.98153 5.53695 5.90495 5.5679C5.82836 5.59885 5.75879 5.64492 5.70038 5.70335C5.64198 5.76179 5.59593 5.8314 5.565 5.90802C5.53407 5.98465 5.51887 6.06672 5.52033 6.14935C5.52179 6.23198 5.53986 6.31347 5.57348 6.38895C5.6071 6.46444 5.65557 6.53238 5.716 6.58872L7.12654 8L5.716 9.41128C5.65557 9.46762 5.6071 9.53556 5.57348 9.61105C5.53986 9.68653 5.52179 9.76802 5.52033 9.85065C5.51887 9.93328 5.53407 10.0154 5.565 10.092C5.59593 10.1686 5.64198 10.2382 5.70038 10.2966C5.75879 10.3551 5.82836 10.4012 5.90495 10.4321C5.98153 10.4631 6.06356 10.4783 6.14615 10.4768C6.22873 10.4753 6.31018 10.4572 6.38563 10.4236C6.46108 10.39 6.52898 10.3415 6.58529 10.281L7.99583 8.86974L9.40638 10.281C9.46269 10.3415 9.53059 10.39 9.60604 10.4236C9.68149 10.4572 9.76293 10.4753 9.84552 10.4768C9.92811 10.4783 10.0101 10.4631 10.0867 10.4321C10.1633 10.4012 10.2329 10.3551 10.2913 10.2966C10.3497 10.2382 10.3957 10.1686 10.4267 10.092C10.4576 10.0154 10.4728 9.93328 10.4713 9.85065C10.4699 9.76802 10.4518 9.68653 10.4182 9.61105C10.3846 9.53556 10.3361 9.46762 10.2757 9.41128L8.86513 8L10.2757 6.58872C10.3361 6.53238 10.3846 6.46444 10.4182 6.38895C10.4518 6.31347 10.4699 6.23198 10.4713 6.14935C10.4728 6.06672 10.4576 5.98465 10.4267 5.90802C10.3957 5.8314 10.3497 5.76179 10.2913 5.70335C10.2329 5.64492 10.1633 5.59885 10.0867 5.5679C10.0101 5.53695 9.92811 5.52175 9.84552 5.52321C9.76293 5.52466 9.68149 5.54275 9.60604 5.57639C9.53059 5.61002 9.46269 5.65851 9.40638 5.71897L7.99583 7.13026L6.58529 5.71897Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Free Support</span>
                  </li>
                </ul>
                <div className="cs-pricing_btn">
                  <a href="#" className="cs-btn cs-size_md">
                    <span>Buy Now</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="cs-height_25 cs-height_lg_25"></div>
          </div>
          <div className="col-lg-4">
            <div className="cs-pricing_table cs-style1">
              <div className="cs-pricing_head">
                <div className="cs-pricing_heading">
                  <div className="cs-pricing_icon cs-center">
                    <img
                      src="../../../img/icons/pricing_icon_3.svg"
                      alt="Icon"
                    />
                  </div>
                  <h2 className="cs-pricing_title cs-m0">Bronze</h2>
                </div>
                <div className="cs-price cs-primary_font">
                  <b className="cs-accent_color">$99</b>
                  <span>/month</span>
                </div>
                <div className="cs-pricing_lable">Get 7 Days Free Trial</div>
              </div>
              <div className="cs-pricing_body">
                <ul className="cs-mp0 cs-pricing_feature">
                  <li>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.18215 0C3.771 0 0.18219 3.58872 0.18219 7.99996C0.18219 12.4112 3.771 16 8.18215 16C12.5933 16 16.1821 12.4112 16.1821 7.99996C16.1821 3.58872 12.5934 0 8.18215 0ZM12.7759 6.6487L7.7499 11.6747C7.53619 11.8884 7.25212 12.006 6.94993 12.006C6.64774 12.006 6.36366 11.8884 6.14996 11.6747L3.58843 9.11314C3.37473 8.89944 3.25702 8.61536 3.25702 8.31317C3.25702 8.01089 3.37473 7.72682 3.58843 7.51311C3.80205 7.29941 4.08613 7.18171 4.3884 7.18171C4.69059 7.18171 4.97475 7.29941 5.18837 7.5132L6.94984 9.27459L11.1758 5.04867C11.3895 4.83497 11.6735 4.71735 11.9757 4.71735C12.2779 4.71735 12.562 4.83497 12.7757 5.04867C13.217 5.48994 13.217 6.2076 12.7759 6.6487Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Unlimited users</span>
                  </li>
                  <li>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.18215 0C3.771 0 0.18219 3.58872 0.18219 7.99996C0.18219 12.4112 3.771 16 8.18215 16C12.5933 16 16.1821 12.4112 16.1821 7.99996C16.1821 3.58872 12.5934 0 8.18215 0ZM12.7759 6.6487L7.7499 11.6747C7.53619 11.8884 7.25212 12.006 6.94993 12.006C6.64774 12.006 6.36366 11.8884 6.14996 11.6747L3.58843 9.11314C3.37473 8.89944 3.25702 8.61536 3.25702 8.31317C3.25702 8.01089 3.37473 7.72682 3.58843 7.51311C3.80205 7.29941 4.08613 7.18171 4.3884 7.18171C4.69059 7.18171 4.97475 7.29941 5.18837 7.5132L6.94984 9.27459L11.1758 5.04867C11.3895 4.83497 11.6735 4.71735 11.9757 4.71735C12.2779 4.71735 12.562 4.83497 12.7757 5.04867C13.217 5.48994 13.217 6.2076 12.7759 6.6487Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Unlimited customers</span>
                  </li>
                  <li>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.18215 0C3.771 0 0.18219 3.58872 0.18219 7.99996C0.18219 12.4112 3.771 16 8.18215 16C12.5933 16 16.1821 12.4112 16.1821 7.99996C16.1821 3.58872 12.5934 0 8.18215 0ZM12.7759 6.6487L7.7499 11.6747C7.53619 11.8884 7.25212 12.006 6.94993 12.006C6.64774 12.006 6.36366 11.8884 6.14996 11.6747L3.58843 9.11314C3.37473 8.89944 3.25702 8.61536 3.25702 8.31317C3.25702 8.01089 3.37473 7.72682 3.58843 7.51311C3.80205 7.29941 4.08613 7.18171 4.3884 7.18171C4.69059 7.18171 4.97475 7.29941 5.18837 7.5132L6.94984 9.27459L11.1758 5.04867C11.3895 4.83497 11.6735 4.71735 11.9757 4.71735C12.2779 4.71735 12.562 4.83497 12.7757 5.04867C13.217 5.48994 13.217 6.2076 12.7759 6.6487Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Access from anywhere</span>
                  </li>
                  <li>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.18215 0C3.771 0 0.18219 3.58872 0.18219 7.99996C0.18219 12.4112 3.771 16 8.18215 16C12.5933 16 16.1821 12.4112 16.1821 7.99996C16.1821 3.58872 12.5934 0 8.18215 0ZM12.7759 6.6487L7.7499 11.6747C7.53619 11.8884 7.25212 12.006 6.94993 12.006C6.64774 12.006 6.36366 11.8884 6.14996 11.6747L3.58843 9.11314C3.37473 8.89944 3.25702 8.61536 3.25702 8.31317C3.25702 8.01089 3.37473 7.72682 3.58843 7.51311C3.80205 7.29941 4.08613 7.18171 4.3884 7.18171C4.69059 7.18171 4.97475 7.29941 5.18837 7.5132L6.94984 9.27459L11.1758 5.04867C11.3895 4.83497 11.6735 4.71735 11.9757 4.71735C12.2779 4.71735 12.562 4.83497 12.7757 5.04867C13.217 5.48994 13.217 6.2076 12.7759 6.6487Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Accept payments 24/7</span>
                  </li>
                  <li>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.18215 0C3.771 0 0.18219 3.58872 0.18219 7.99996C0.18219 12.4112 3.771 16 8.18215 16C12.5933 16 16.1821 12.4112 16.1821 7.99996C16.1821 3.58872 12.5934 0 8.18215 0ZM12.7759 6.6487L7.7499 11.6747C7.53619 11.8884 7.25212 12.006 6.94993 12.006C6.64774 12.006 6.36366 11.8884 6.14996 11.6747L3.58843 9.11314C3.37473 8.89944 3.25702 8.61536 3.25702 8.31317C3.25702 8.01089 3.37473 7.72682 3.58843 7.51311C3.80205 7.29941 4.08613 7.18171 4.3884 7.18171C4.69059 7.18171 4.97475 7.29941 5.18837 7.5132L6.94984 9.27459L11.1758 5.04867C11.3895 4.83497 11.6735 4.71735 11.9757 4.71735C12.2779 4.71735 12.562 4.83497 12.7757 5.04867C13.217 5.48994 13.217 6.2076 12.7759 6.6487Z"
                        fill="#47C4C0"
                      />
                    </svg>
                    <span>Free Support</span>
                  </li>
                </ul>
                <div className="cs-pricing_btn">
                  <a href="#" className="cs-btn cs-size_md">
                    <span>Buy Now</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="cs-height_25 cs-height_lg_25"></div>
          </div>
        </div>
        <div className="cs-height_75 cs-height_lg_45"></div>
      </div>
    </section>
  );
}
