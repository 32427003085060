import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';  // Asegúrate de importar `db` desde tu archivo de configuración de Firebase
import Preloader from "../../../component/Preloader/Preloader";
import Item from "../../../component/Item/Item";

export default function Files() {
    const [items, setItems] = useState([]);

    const fetchData = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "items"));
            const response = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setItems(response);
            console.log(response)
        } catch (error) {
            console.error("Error fetching items:", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div id="files" style={{
            padding: 20
        }}>
            <div className="cs-height_80 cs-height_lg_80"></div>
            <section>
                {/*<Preloader></Preloader>*/}
                <h1>FILES</h1>

                {items.map(item => (
                    <div key={item.id}>
                        <Item {...item} />
                    </div>
                ))}
            </section>
        </div>
    )
}